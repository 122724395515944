import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Auth",
    component: () => import("../views/Auth/Auth.vue"),
  },
  {
    path: "/assignment",
    name: "Assignment",
    component: () => import("../views/Assignment/index.vue"),
    meta: {
      links: [
        {
          view: true,
          name: { name: "Assignment" },
          text: "Назначение уполномоченных",
        },
        {
          view: true,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: true,
          name: { name: "editCR", params: { id: "new", stage: 1, status: 1 } },
          text: "Создать уведомление",
        },
        {
          view: true,
          name: { name: "editCR", params: { id: "newZ", stage: 2, status: 6 } },
          text: "Создать заявление",
        },
        { view: false, name: { name: "ProfileMPNKO" }, text: "Данные профиля" },
        {
          view: false,
          name: { name: "ListUserForMZ" },
          text: "Пользователи МПНКО",
        },
        { view: true, name: { name: "History" }, text: "История изменения КР" },
        { view: true, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/listCr",
    name: "listCr",
    component: () => import("../views/ListCr/listCr.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "new", stage: 1, status: 1 } },
          text: "Создать уведомление",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "newZ", stage: 2, status: 6 } },
          text: "Создать заявление",
        },
        { view: false, name: { name: "ProfileMPNKO" }, text: "Данные профиля" },
        {
          view: false,
          name: { name: "ListUserForMZ" },
          text: "Пользователи МПНКО",
        },
        {
          view: false,
          name: { name: "History" },
          text: "История изменения КР",
        },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/History",
    name: "History",
    component: () => import("../views/History/index.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "ListUserForMZ" },
          text: "Пользователи МПНКО",
        },
        {
          view: false,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
        {
          view: false,
          name: { name: "History" },
          text: "История изменения КР",
        },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
        { view: true, name: { name: "Companies" }, text: "Справочник МПНКО" },
      ],
    },
  },
  {
    path: "/editCR/:id",
    name: "editCR",
    component: () => import("../views/EditCr/editCr.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "new", stage: 1, status: 1 } },
          text: "Создать уведомление",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "newZ", stage: 2, status: 6 } },
          text: "Создать заявление",
        },
        { view: false, name: { name: "ProfileMPNKO" }, text: "Данные профиля" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/viewCR/:id",
    name: "ViewCR",
    component: () => import("../views/ViewCr/viewCr.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
      ],
    },
  },
  {
    path: "/application",
    name: "Application",
    component: () => import("../views/ViewCr/application.vue"),
    meta: {
      links: [
        {
          view: true,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
      ],
    },
  },
  {
    path: "/applicationSigned",
    name: "ApplicationSigned",
    component: () => import("../views/ViewCr/applicationSigned.vue"),
    meta: {
      links: [
        {
          view: true,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
      ],
    },
  },
  {
    path: "/addUser",
    name: "AddUser",
    component: () => import("../views/AddUser/addUser.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        { view: false, name: { name: "#" }, text: "Пользователи МПНКО" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/addCompanies",
    name: "AddCompanies",
    component: () => import("../views/AddCompanies/index.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        { view: false, name: { name: "#" }, text: "Пользователи МПНКО" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/listUser",
    name: "ListUser",
    component: () => import("../views/ListUser/listUser.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
        {
          view: false,
          name: { name: "History" },
          text: "История изменения КР",
        },
        { view: true, name: { name: "Companies" }, text: "Справочник МПНКО" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/editUser/:id",
    name: "EditUser",
    component: () => import("../views/EditUser/editUser.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
      ],
    },
  },
  {
    path: "/editCompanies/:id",
    name: "EditCompanies",
    component: () => import("../views/AddCompanies/index.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
      ],
    },
  },
  {
    path: "/listUserForMZ",
    name: "ListUserForMZ",
    component: () => import("../views/ListUserForMZ/listUserForMZ.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "ListUserForMZ" },
          text: "Пользователи МПНКО",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "new", stage: 1, status: 1 } },
          text: "Создать уведомление",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "newZ", stage: 2, status: 6 } },
          text: "Создать заявление",
        },
        {
          view: false,
          name: { name: "History" },
          text: "История изменения КР",
        },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/editUserForMZ",
    name: "EditUserForMZ",
    component: () => import("../views/EditUserForMZ/editUserForMZ.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "ListUserForMZ" },
          text: "Пользователи МПНКО",
        },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/profileMPNKO",
    name: "ProfileMPNKO",
    component: () => import("../views/ProfileMPNKO/profileMPNKO.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "new" } },
          text: "Создать уведомление",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "newZ" } },
          text: "Создать заявление",
        },
        { view: false, name: { name: "ProfileMPNKO" }, text: "Данные профиля" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () => import("../views/Analytics/analytics.vue"),
    meta: {
      links: [
        {
          view: false,
          name: { name: "listCr" },
          text: "Клинические рекомендации",
        },
        {
          view: false,
          name: { name: "ListUserForMZ" },
          text: "Пользователи МПНКО",
        },
        {
          view: false,
          name: { name: "History" },
          text: "История изменения КР",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "new" } },
          text: "Создать уведомление",
        },
        {
          view: false,
          name: { name: "editCR", params: { id: "newZ" } },
          text: "Создать заявление",
        },
        { view: false, name: { name: "ProfileMPNKO" }, text: "Данные профиля" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
  {
    path: "/Companies",
    name: "Companies",
    component: () => import("../views/Companies/index.vue"),
    meta: {
      links: [
        {
          view: true,
          name: { name: "ListUser" },
          text: "Учетные записи пользователей",
        },
        {
          view: true,
          name: { name: "History" },
          text: "История изменения КР",
        },
        { view: true, name: { name: "Companies" }, text: "Справочник МПНКО" },
        { view: false, name: { name: "analytics" }, text: "Аналитика" },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
